import React from 'react';
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { graphql } from 'gatsby';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';
import WeekBannerQL from '../components/WeekBannerQL';
import './weekTemplate.css';

import {
    Container, Row, Col,
    ListGroup, ListGroupItem,
    ListGroupItemHeading, ListGroupItemText }
  from 'reactstrap';


const SmoothScroll = () => ( // "top" id defined in Header.jsx
    <div>
        <AnchorLink href='#top' id="back-to-top-btn"></AnchorLink>
    </div>
);


export const query = graphql`
    query ($slug: String!) {
        markdownRemark (fields: { slug: { eq: $slug } }) {
            excerpt(pruneLength: 160)
            frontmatter {
                weekId
                title
                publishDate(formatString: "MMM DD, YYYY")
                weekImg {
                    publicURL
                }
                image: weekImg {
                    childImageSharp {
                      resize(width: 1200) {
                        src
                        height
                        width
                      }
                    }
                  }
                tags
                assignment
                download
            }
            parent {
                ... on File {
                  modifiedTime(formatString: "MMM DD, YYYY")
                }
            }
            html
        }
    }
`;

// export const query = graphql`
//     query ($slug: String!) {
//         contentfulBlogPost (slug: { eq: $slug }) {
//             title
//             publishDate(formatString: "MMMM Do, YYYY")
//             body {
//                 json
//             }
//         }
//     }
// `;

export default function Blog(props) {
    // for Contentful CMS
    // const options = {
    //     renderNode: {
    //         "embedded-asset-block": (node) => {
    //             const alt = node.date.target.fields.title['en-US'];
    //             const url = node.date.target.fields.title['en-US'].url;
    //             return <img alt={alt} src={url} />
    //         }
    //     }
    // }
    const post = props.data.markdownRemark;
    const image = post.frontmatter.image
      ? post.frontmatter.image.childImageSharp.resize
      : null;

    return (
        <Layout>
            <SEO
                title={`Week ${post.frontmatter.weekId} - ${post.frontmatter.title}`}
                description={post.frontmatter.assignment || post.excerpt}
                image={image}
                pathname={props.location.pathname}
            />
            <WeekBannerQL id="banner"
                weekId={post.frontmatter.weekId}
                title={post.frontmatter.title}
                publishDate={post.frontmatter.publishDate}
                updateDate={post.parent.modifiedTime}
                weekImg={post.frontmatter.weekImg.publicURL}
                tags={post.frontmatter.tags}
                assignment={post.frontmatter.assignment}
                download={post.frontmatter.download}
            />
            <div className='mb-5'>
                <Container>
                    <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
                </Container>
            </div>
            {/* <SEO title={props.data.contentfulBlogPost.title} />
            <h1>{props.data.contentfulBlogPost.title}</h1>
            <p>{props.data.contentfulBlogPost.publishDate}</p>
            {documentToReactComponents(props.data.contentfulBlogPost.body.json)} */}

            <SmoothScroll />
        </Layout>
    )
}