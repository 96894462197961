import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Jumbotron, Button } from 'reactstrap';

export default function WeekBannerQL({ weekId, title, publishDate, updateDate, weekImg, tags, assignment, download }) {
  return (
    <div>
      <Jumbotron>
        <h1 className="display-3">
          Week {weekId}: {title}
          <a href={weekImg}>
            <img alt='' className='mx-3 week-avatar-img' src={weekImg} />
          </a>
        </h1>
        <p className="lead">{tags}</p>
        <hr className="my-2" />
        <p className='text-secondary'>Assignments: <span className='font-italic font-weight-light'>{assignment}</span></p>
        <small className='text-muted'>Published on: {publishDate}</small>
        <br />
        <small className='text-muted'>Last updated on: {updateDate}</small>
        <p className="lead mt-3">
          <Button color="primary" href={download} target="_blank">View Source Files</Button>
        </p>
      </Jumbotron>
    </div>
  );
}